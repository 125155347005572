import { create } from "zustand";
import axios from "axios";

 export type Entry = {
    longitude: string;
    latitude: string;
    image: string;
    Ort: string;
    AutomatenTyp: string;
};

export type useGetAllPointsStoreProps = {
    entries: Entry[];
};

type Store = {
    entries: Entry[];
    getAllPointsData: () => void;
};

export const useGetAllPointsStore = create<Store>((set, get) => ({
    entries: [],
    getAllPointsData: async () => {
        const response = await axios.get("https://api.pflegecreme123.at/api/allpoints");
        set({ entries: response.data.entries });
    },
}));