import React from 'react';
import {AppRouter} from "./appRouter";
import './App.css';
import {QueryClientProvider, QueryClient} from "react-query";

const queryClient = new QueryClient();

function App() {
  return (
      <QueryClientProvider client={queryClient}>
          <AppRouter />
      </QueryClientProvider>
  );
}

export default App;
