import { Dialog,Transition } from "@headlessui/react";
import {Fragment} from "react";

interface ErrorModalProps {
    error: string | null;
    setError: (value: string | null) => void;
}
export const ErrorModal: React.FC<ErrorModalProps> = ({ error, setError }) => {
    return (
        <Transition.Root show={!!error} as={Fragment} >
            <Dialog
                open={!!error}
                onClose={() => setError(null)}
                as="div"
                static
                className="fixed inset-0 z-10 flex items-center justify-center"
            >
                <div className="bg-white p-4 rounded">
                    <p>{error}</p>
                    <button className="bg-red-500 p-2 mt-2 text-white" onClick={() => setError(null)}>
                        Close
                    </button>
                </div>
            </Dialog>
        </Transition.Root>

    );
};
