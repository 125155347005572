import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

interface LoadingModalProps {
    isLoading: boolean;
}
export const LoadingModal: React.FC<LoadingModalProps> = ({ isLoading }) => {
    return (
        <Transition.Root show={isLoading} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                open={isLoading}
                onClose={() => {}}
            >
                <div className="flex items-center justify-center min-h-screen">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                    <div className="bg-white p-6 rounded shadow-md max-w-sm w-full">
                        <Dialog.Title className="text-lg font-semibold">
                            Uploading...
                        </Dialog.Title>
                        <Dialog.Description className="mt-2">
                            Please wait while your image and location data are being uploaded.
                        </Dialog.Description>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
