import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useRef, useState } from "react";
import { useGetAllPointsStore } from "../Storage/getPoints";
import { AddPointWithPhoto } from "./AddPointWithPhoto";
import { ShowMarkers } from "./ShowMarkers";
import { UserLocation } from "./UserLocation";

export const Map = () => {
    mapboxgl.accessToken =
        "pk.eyJ1IjoiZG9tYW5pZ3QiLCJhIjoiY2xnanVwbWszMDZlNDNwb2VpdGllODlsNSJ9.O4tU-b90wCUriHZji9WZEA";
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const [openAddPhoto,setOpenAddPhoto]=useState(false);
    const [mapInstance, setMapInstance] = useState<mapboxgl.Map | null>(null);

    const { entries, getAllPointsData } = useGetAllPointsStore();

    useEffect(() => {
        const initializeMap = async () => {
            const currentPosition = await getCurrentPosition();
            const { latitude, longitude } = currentPosition.coords;

            const map = new mapboxgl.Map({
                container: mapContainer.current ? mapContainer.current : '',
                style: 'mapbox://styles/domanigt/clgjutm2j007u01qx7fdr8l7g',
                center: [longitude, latitude],
                zoom: 9,
            });
            map.on('load', async () => {
                setMapInstance(map);
                await getAllPointsData();
            });
            return () => {
                map.remove();
            };
        };

        initializeMap();
    }, [getAllPointsData]);
    const getCurrentPosition = () => {
        return new Promise<GeolocationPosition>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
    };

    return (
        <div className="relative h-screen w-screen">
            <div ref={mapContainer} className="h-screen w-screen"></div>
            <div className={`absolute top-0 left-0 p-4 ${openAddPhoto?'flex':'hidden'}`}>
                <AddPointWithPhoto showDashboard={openAddPhoto} closeBoard={()=>setOpenAddPhoto(!openAddPhoto)} />
            </div>
            <div className="absolute bottom-[0px] flex justify-center p-3 w-full bg-white z-50 left-0 ">
                <button className={`bg-emerald-500 p-3 font-bold text-xl rounded-full ${!openAddPhoto?'flex':'hidden'}`} onClick={()=>setOpenAddPhoto(!openAddPhoto)}>+</button>
           </div>

            {mapInstance && <UserLocation map={mapInstance} />}
            {mapInstance && <ShowMarkers map={mapInstance} entries={entries} />}
        </div>
    );
};


