import {Route,BrowserRouter, Routes} from "react-router-dom";
import {Map} from "./Components/Map";

export const AppRouter=()=>{
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Map/>}></Route>
            </Routes>
        </BrowserRouter>
    )
}