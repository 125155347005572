import { Dialog, Transition } from "@headlessui/react";
import {Fragment} from "react";


type SuccessModalProps= {
    isModalOpen: boolean;
    toggleModal: () => void;
}

export const SuccessModal= ({ isModalOpen, toggleModal }:SuccessModalProps) => {
    return (
        <Transition.Root show={isModalOpen} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                open={isModalOpen}
                onClose={toggleModal}
            >
                <div className="flex items-center justify-center min-h-screen z-30">
                    <Dialog.Overlay className="fixed  bg-black opacity-50" />
                    <div className="bg-white p-6 rounded shadow-md max-w-sm w-full">
                        <Dialog.Title className="text-lg font-semibold">
                            Upload successful
                        </Dialog.Title>
                        <Dialog.Description className="mt-2">
                            Your image and location data have been uploaded successfully.
                        </Dialog.Description>
                        <div className="mt-4">
                            <button
                                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                                onClick={toggleModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
       );
};
