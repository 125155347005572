import { useEffect, useState } from 'react';
import mapboxgl, { Marker } from 'mapbox-gl';

interface UserLocationProps {
    map: mapboxgl.Map;
}

export const UserLocation: React.FC<UserLocationProps> = ({ map }) => {
    const [userLocationMarker, setUserLocationMarker] = useState<Marker | null>(null);

    useEffect(() => {
        const addUserLocationMarker = async () => {
            const currentPosition = await getCurrentPosition();
            const { latitude, longitude } = currentPosition.coords;
            const marker = new Marker({ color: '#54f542' })
                .setLngLat([longitude, latitude])
                .setPopup(new mapboxgl.Popup().setHTML('<h1>Mei derzeitige Position</h1>'))
                .addTo(map);
            setUserLocationMarker(marker);

            const watchId = navigator.geolocation.watchPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    marker.setLngLat([longitude, latitude]);
                },
                (error) => {
                    console.error(error);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0,
                }
            );

            return () => {
                userLocationMarker?.remove();
                navigator.geolocation.clearWatch(watchId);
            };
        };

        addUserLocationMarker();
    }, [map]);

    const getCurrentPosition = () => {
        return new Promise<GeolocationPosition>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
    };

    return null;
};
