import { useEffect } from 'react';
import mapboxgl, { Marker } from 'mapbox-gl';
import { Entry } from '../Storage/getPoints';

interface ShowMarkersProps {
    map: mapboxgl.Map;
    entries: Entry[];
}
export const ShowMarkers: React.FC<ShowMarkersProps> = ({ map, entries }) => {
    useEffect(() => {
        const markers: Marker[] = [];
        entries.forEach((entry) => {
            const { longitude, latitude,image  } = entry;
            const lng = parseFloat(longitude);
            const lat = parseFloat(latitude);
            let test=""

            if(image.includes('public')){
                test= image.replace('public','storage')
                console.log(test)
            }
            else{test=image}
            const imageUrl="https://api.pflegecreme123.at/"+test;
            const marker = new Marker()
                .setPopup(new mapboxgl.Popup().setHTML(`<div class="bg-amber-400"><img src=${imageUrl}></div>`))
                .setLngLat([lng, lat])
                .addTo(map);
            markers.push(marker);
        });
        return () => {
            markers.forEach((marker) => {
                marker.remove();
            });
        };
    }, [map, entries]);
    return null;
};
