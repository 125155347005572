import {useEffect, useState} from "react";
import { ErrorModal } from "./ErrorModal";
import { LoadingModal } from "./LoadingModal";
import { SuccessModal } from "./SucessModal";
import axios from "axios";
import Uppy, {UppyFile} from "@uppy/core";
import { Dashboard } from "@uppy/react";
import Webcam from '@uppy/webcam'

//styles
import '@uppy/core/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

type AddPointWithPhotoProps={
    showDashboard:boolean
    closeBoard:()=>void;
};

const uppy = new Uppy({
    autoProceed: false,
    restrictions: {
        allowedFileTypes: ["image/*"],
    },
}).use(Webcam,{facingMode:'environment'});
export const AddPointWithPhoto = ({showDashboard,closeBoard}:AddPointWithPhotoProps) => {
    const [isDashboardOpen, setIsDashboardOpen]=useState();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [location, setLocation] = useState<GeolocationPosition | null>(null);
    const [photo, setPhoto]=useState<UppyFile>();
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    const handleGetLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocation(position);
            },
            (error) => {
                console.error("Error getting location:", error);
            },
            { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
        );};
    const handleSubmit = async () => {
        try{
            const file = uppy.getFile(uppy.getFiles()[0].id);
            setPhoto(file);
        }catch (e){
        }
        setTimeout(() => {
            handleGetLocation();
        }, 1000);
        if (photo && location != null) {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("entries[0][image]", photo.data);
            formData.append("entries[0][latitude]", location.coords.latitude.toString());
            formData.append("entries[0][longitude]", location.coords.longitude.toString());
            formData.append("entries[0][Ort]", "Your Ort value"); // Add the Ort value here
            formData.append("entries[0][AutomatenTyp]", "Your AutomatenTyp value"); // Add the AutomatenTyp value here

            try {
                const response = await axios.post("https://api.pflegecreme123.at/api/allpoints", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                console.log("Image and location data uploaded:", response.data);
                setIsLoading(false);
                if (response.status === 200) {
                    toggleModal();
                }
            } catch (error) {
                const err: any = error;
                setIsLoading(false);

                if (err.response) {
                    // The server responded with a status code outside the range of 2xx
                    setError(`An error occurred while uploading the data: ${err.response.status} - ${err.response.data}`);
                } else if (err.request) {
                    // The request was made, but no response was received
                    setError("An error occurred while uploading the data. The server did not respond. Please try again.");
                } else {
                    // Something happened in setting up the request that triggered an Error
                    setError(`An error occurred while uploading the data: ${err.message}. Please try again.`);
                }
            }
        }else{
            setError('Eingabe ungültig')
        }};
    return (
        <div className="flex flex-col gap-5">
            <div className={`${showDashboard ?'flex':'hidden'} flex justify-center`}>
                <button className="z-50 text-white absolute rounded-full bg-red-500 p-3" onClick={closeBoard}>x</button>
                <Dashboard uppy={uppy} plugins={['Webcam']} height={500} width={320}  />
            </div>

            {/* Remove the input element */}
            <button className="bg-emerald-500 p-3" onClick={handleSubmit}  >
                Submit
            </button>
            <ErrorModal error={error} setError={setError} />
            <LoadingModal isLoading={isLoading} />
            <SuccessModal isModalOpen={isModalOpen} toggleModal={toggleModal} />
        </div>
    );
};
